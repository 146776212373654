import React, { useState, useEffect } from 'react';
import s from './SixthQuestion.module.css';
import styles from '../../styles.module.css';
import { closeApp } from '../../utils/constants';

export default function SixthQuestion({
  openSeventh,
  tasty,
  deleteTasty,
  openFinal,
}) {
  let counter = 0;
  let previos = 0;
  useEffect(() => {
    Object.values(tasty).map((value) => {
      if (value) {
        previos = previos + 1;
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const answers = e.target.querySelectorAll('input');
    console.log(answers);
    for (const answer of answers) {
      if (answer.checked) {
        if (answer.value === 's6') {
          counter = counter + 1;
          deleteTasty('sun');
        } else if (answer.value === 'a6') {
          counter = counter + 1;
          deleteTasty('arbor');
        } else if (answer.value === 't6') {
          counter = counter + 1;
          deleteTasty('twilight');
        }
      }
    }

    if (counter === previos) {
      closeApp();
    } else {
      openSeventh();
    }
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h3>
        Скажите, насколько вам понравилcя вкус HEETS с капсулами, которые вы
        попробовали? Оцените по 5-ти балльной шкале, где 1 – совершенно не
        понравился, 5 – очень понравился
      </h3>
      <div className={s.container}>
        {tasty.sun && (
          <div className={s.tastyContainer}>
            <h2>SUN</h2>
            <label htmlFor="s1" className={s.label}>
              <input type="radio" id="s1" name="sun" value="s1" required />1
            </label>
            <label htmlFor="s2" className={s.label}>
              <input type="radio" id="s2" name="sun" value="s2" />2
            </label>
            <label htmlFor="s3" className={s.label}>
              <input type="radio" id="s3" name="sun" value="s3" />3
            </label>
            <label htmlFor="s4" className={s.label}>
              <input type="radio" id="s4" name="sun" value="s4" />4
            </label>
            <label htmlFor="s5" className={s.label}>
              <input type="radio" id="s5" name="sun" value="s5" />5
            </label>
            <label htmlFor="s6" className={s.label}>
              <input type="radio" id="s6" name="sun" value="s6" />
              Не пробовал эти стики
            </label>
          </div>
        )}
        {tasty.arbor && (
          <div className={s.tastyContainer}>
            <h2>ARBOR</h2>
            <label htmlFor="a1" className={s.label}>
              <input type="radio" id="a1" name="arbor" value="a1" />1
            </label>
            <label htmlFor="a2" className={s.label}>
              <input type="radio" id="a2" name="arbor" value="a2" />2
            </label>
            <label htmlFor="a3" className={s.label}>
              <input type="radio" id="a3" name="arbor" value="a3" />3
            </label>
            <label htmlFor="a4" className={s.label}>
              <input type="radio" id="a4" name="arbor" value="a4" />4
            </label>
            <label htmlFor="a5" className={s.label}>
              <input type="radio" id="a5" name="arbor" value="a5" />5
            </label>
            <label htmlFor="a6" className={s.label}>
              <input type="radio" id="a6" name="arbor" value="a6" />
              Не пробовал эти стики
            </label>
          </div>
        )}
        {tasty.twilight && (
          <div className={s.tastyContainer}>
            <h2>TWILIGHT</h2>
            <label htmlFor="t1" className={s.label}>
              <input type="radio" id="t1" name="twilight" value="t1" />1
            </label>
            <label htmlFor="t2" className={s.label}>
              <input type="radio" id="t2" name="twilight" value="t2" />2
            </label>
            <label htmlFor="t3" className={s.label}>
              <input type="radio" id="t3" name="twilight" value="t3" />3
            </label>
            <label htmlFor="t4" className={s.label}>
              <input type="radio" id="t4" name="twilight" value="t4" />4
            </label>
            <label htmlFor="t5" className={s.label}>
              <input type="radio" id="t5" name="twilight" value="t5" />5
            </label>
            <label htmlFor="t6" className={s.label}>
              <input type="radio" id="t6" name="twilight" value="t6" />
              Не пробовал эти стики
            </label>
          </div>
        )}
      </div>
      <button type="submit" className={styles.button}>
        Ответить
      </button>
    </form>
  );
}
