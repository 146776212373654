import React, { useState } from 'react';
import s from './Main.module.css';
import FirstQuestion from '../FirstQuestion/FirstQuestion';
import SecondQuestion from '../SecondQuestion/SecondQuestion';
import ThirdQuestion from '../ThirdQuestion/ThirdQuestion';
import FourthQuestion from '../FourthQuestion/FourthQuestion';
import FifthQuestion from '../FifthQuestion/FifthQuestion';
import SixthQuestion from '../SixthQuestion/SixthQuestion';
import SeventhQuestion from '../SeventhQuestion/SeventhQuestion';
import EighthQuestion from '../EighthQuestion/EighthQuestion';
import FinalScreen from '../FinalScreen/FinalScreen';

export default function Main() {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false);
  const [eighth, setEighth] = useState(false);
  const [final, setFinal] = useState(false);
  const [tasty, setTasty] = useState({
    sun: true,
    arbor: true,
    twilight: true,
  });

  const openSecond = () => {
    setFirst(false);
    setSecond(true);
  };

  const openThird = () => {
    setSecond(false);
    setThird(true);
  };
  const openFourth = () => {
    setThird(false);
    setFourth(true);
  };
  const openFifth = () => {
    setFourth(false);
    setFifth(true);
  };

  const openSixth = () => {
    setFifth(false);
    setSixth(true);
  };

  const openSeventh = () => {
    setSixth(false);
    setSeventh(true);
  };

  const openEighth = () => {
    setSeventh(false);
    setEighth(true);
  };

  const openFinal = () => {
    setSecond(false);
    setFourth(false);
    setFifth(false);
    setSixth(false);
    setSeventh(false);
    setEighth(false);
    setFinal(true);
  };

  const deleteTasty = (key) => {
    setTasty((tasty) => ({ ...tasty, [key]: false }));
  };
  return (
    <div className={s.main}>
      {first && <FirstQuestion openSecond={openSecond} openFinal={openFinal} />}
      {second && <SecondQuestion openThird={openThird} />}
      {third && <ThirdQuestion openFourth={openFourth} />}
      {fourth && <FourthQuestion openFifth={openFifth} openFinal={openFinal} />}
      {fifth && (
        <FifthQuestion
          openSixth={openSixth}
          openFinal={openFinal}
          deleteTasty={deleteTasty}
          tasty={tasty}
        />
      )}
      {sixth && (
        <SixthQuestion
          openSeventh={openSeventh}
          openFinal={openFinal}
          tasty={tasty}
          deleteTasty={deleteTasty}
        />
      )}
      {seventh && (
        <SeventhQuestion
          openEighth={openEighth}
          openFinal={openFinal}
          tasty={tasty}
        />
      )}
      {eighth && <EighthQuestion openFinal={openFinal} />}
      {final && <FinalScreen />}
    </div>
  );
}
