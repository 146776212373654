import React, { useState } from 'react';
import s from './FirstQuestion.module.css';
import styles from '../../styles.module.css';

export default function FirstQuestion({ openSecond }) {
  const [other, setOther] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const asnwers = document.querySelectorAll('input[type=checkbox]:checked');
    console.log(asnwers);
    if (asnwers.length !== 0) {
      openSecond();
    }
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h1>Какие чувства вызывает у вас бренд IQOS?</h1>
      <div className={s.container}>
        <label htmlFor="1">
          <input type="checkbox" id="1" name="feel" />
          Доверие
        </label>
        <label htmlFor="2">
          <input type="checkbox" id="2" name="feel" />
          Забота
        </label>
        <label htmlFor="3">
          <input type="checkbox" id="3" name="feel" />
          Удовлетворение
        </label>
        <label htmlFor="4">
          <input type="checkbox" id="4" name="feel" />
          Воодушевление
        </label>
        <label htmlFor="5">
          <input type="checkbox" id="5" name="feel" />
          Удовольствие
        </label>
        <label htmlFor="other">
          <input type="checkbox" onChange={() => setOther(!other)} id="other" />
          Другое
        </label>
        {other && (
          <label htmlFor="">
            <input type="text" required />
          </label>
        )}
        <button type="summit" className={styles.button}>
          Ответить
        </button>
      </div>
    </form>
  );
}
