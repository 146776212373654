import React, { useState } from 'react';
import s from './FifthQuestion.module.css';
import { closeApp } from '../../utils/constants';
import styles from '../../styles.module.css';

export default function FifthQuestion({ openSixth, deleteTasty, openFinal }) {
  let counter = 0;
  const onSubmit = (e) => {
    e.preventDefault();
    const answers = e.target.querySelectorAll('input');
    for (const answer of answers) {
      if (answer.checked) {
        if (answer.value === 's8') {
          counter = counter + 1;
          deleteTasty('sun');
        } else if (answer.value === 'a8') {
          counter = counter + 1;
          deleteTasty('arbor');
        } else if (answer.value === 't8') {
          counter = counter + 1;
          deleteTasty('twilight');
        }
      }
    }

    if (counter == 3) {
      closeApp();
    } else {
      openSixth();
    }
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h3>
        Насколько вам нравится дизайн пачки стиков HEETS с капсулой? Оцените по
        7-ми балльной шкале, где 1 – совершенно не нравится, 7 – очень нравится
      </h3>
      <div className={s.container}>
        <div className={s.tastyContainer}>
          <h2>SUN</h2>
          <label htmlFor="s1" className={s.label}>
            <input type="radio" id="s1" name="sun" value="s1" required />1
          </label>
          <label htmlFor="s2" className={s.label}>
            <input type="radio" id="s2" name="sun" value="s2" />2
          </label>
          <label htmlFor="s3" className={s.label}>
            <input type="radio" id="s3" name="sun" value="s3" />3
          </label>
          <label htmlFor="s4" className={s.label}>
            <input type="radio" id="s4" name="sun" value="s4" />4
          </label>
          <label htmlFor="s5" className={s.label}>
            <input type="radio" id="s5" name="sun" value="s5" />5
          </label>
          <label htmlFor="s6" className={s.label}>
            <input type="radio" id="s6" name="sun" value="s6" />6
          </label>
          <label htmlFor="s7" className={s.label}>
            <input type="radio" id="s7" name="sun" value="s7" />7
          </label>
          <label htmlFor="s8" className={s.label}>
            <input type="radio" id="s8" name="sun" value="s8" />
            Не видел эти стики
          </label>
        </div>
        <div className={s.tastyContainer}>
          <h2>ARBOR</h2>
          <label htmlFor="a1" className={s.label}>
            <input type="radio" id="a1" name="arbor" value="a1" required />1
          </label>
          <label htmlFor="a2" className={s.label}>
            <input type="radio" id="a2" name="arbor" value="a2" />2
          </label>
          <label htmlFor="a3" className={s.label}>
            <input type="radio" id="a3" name="arbor" value="a3" />3
          </label>
          <label htmlFor="a4" className={s.label}>
            <input type="radio" id="a4" name="arbor" value="a4" />4
          </label>
          <label htmlFor="a5" className={s.label}>
            <input type="radio" id="a5" name="arbor" value="a5" />5
          </label>
          <label htmlFor="a6" className={s.label}>
            <input type="radio" id="a6" name="arbor" value="a6" />6
          </label>
          <label htmlFor="a7" className={s.label}>
            <input type="radio" id="a7" name="arbor" value="a7" />7
          </label>
          <label htmlFor="a8" className={s.label}>
            <input type="radio" id="a8" name="arbor" value="a8" />
            Не видел эти стики
          </label>
        </div>
        <div className={s.tastyContainer}>
          <h2>TWILIGHT</h2>
          <label htmlFor="t1" className={s.label}>
            <input type="radio" id="t1" name="twilight" value="t1" required />1
          </label>
          <label htmlFor="t2" className={s.label}>
            <input type="radio" id="t2" name="twilight" value="t2" />2
          </label>
          <label htmlFor="t3" className={s.label}>
            <input type="radio" id="t3" name="twilight" value="t3" />3
          </label>
          <label htmlFor="t4" className={s.label}>
            <input type="radio" id="t4" name="twilight" value="t4" />4
          </label>
          <label htmlFor="t5" className={s.label}>
            <input type="radio" id="t5" name="twilight" value="t5" />5
          </label>
          <label htmlFor="t6" className={s.label}>
            <input type="radio" id="t6" name="twilight" value="t6" />6
          </label>
          <label htmlFor="t7" className={s.label}>
            <input type="radio" id="t7" name="twilight" value="t7" />7
          </label>
          <label htmlFor="t8" className={s.label}>
            <input type="radio" id="t8" name="twilight" value="t8" />
            Не видел эти стики
          </label>
        </div>
      </div>
      <button type="submit" className={styles.button}>
        Ответить
      </button>
    </form>
  );
}
