import React from 'react';
import s from './ThirdQuestion.module.css';
import styles from '../../styles.module.css';

export default function ThirdQuestion({ openFourth }) {
  const onSubmit = (e) => {
    e.preventDefault();
    openFourth();
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h1>
        Сколько стиков вашего основного вкуса вы используете в среднем за день?
      </h1>
      <input type="number" required className={s.input} />
      <button type="submit" className={styles.button}>
        Ответить
      </button>
    </form>
  );
}
