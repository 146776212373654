import React from 'react';
import s from './SecondQuestion.module.css';
import styles from '../../styles.module.css';
import { closeApp } from '../../utils/constants';

export default function SecondQuestion({ openThird }) {
  const onSubmit = (e) => {
    e.preventDefault();
    // const answers = e.target.querySelectorAll('input[name="tasty"]');
    const answers = e.target.querySelectorAll('select');
    for (const answer of answers) {
      // if (answer.checked) {
      if (answer.value === 'r23') {
        closeApp();
      } else {
        openThird();
      }
      // }
    }
  };

  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h1>
        Какой вкус HEETS / Fiit вы используете в настоящее время чаще всего?
      </h1>
      <div className={s.container}>
        <select name="tasty" id="tasty">
          <option>HEETS SUN</option>
          <option>HEETS ARBOR</option>
          <option>HEETS TWILIGHT</option>
          <option>HEETS AMARELO FUSE</option>
          <option>HEETS AMBER SELECTION</option>
          <option>HEETS BRONZE SELECTION</option>
          <option>HEETS GOLD SELECTION</option>
          <option>HEETS GREEN ZING</option>
          <option>HEETS PURPLE WAVE</option>
          <option>HEETS RUBY FUSE</option>
          <option>HEETS SATIN FUSE</option>
          <option>HEETS SLATE SELECTION</option>
          <option>HEETS SUMMER BREEZE</option>
          <option>HEETS TURQUOISE SELECTION</option>
          <option>Fiit Regular Deep</option>
          <option>Fiit Regular</option>
          <option>Fiit Marine</option>
          <option>Fiit Tropic</option>
          <option>Fiit Viola</option>
          <option>Fiit Spring</option>
          <option value="r23">Никакой</option>
        </select>
        {/* <label htmlFor="r1" className={s.label}>
          <input type="radio" id="r1" name="tasty" required />
          HEETS SUN
        </label>
        <label htmlFor="r2" className={s.label}>
          <input type="radio" id="r2" name="tasty" />
          HEETS ARBOR
        </label>
        <label htmlFor="r3" className={s.label}>
          <input type="radio" id="r3" name="tasty" />
          HEETS TWILIGHT
        </label>
        <label htmlFor="r4" className={s.label}>
          <input type="radio" id="r4" name="tasty" />
          HEETS AMARELO FUSE
        </label>
        <label htmlFor="r5" className={s.label}>
          <input type="radio" id="r5" name="tasty" />
          HEETS AMBER SELECTION
        </label>
        <label htmlFor="r6" className={s.label}>
          <input type="radio" id="r6" name="tasty" />
          HEETS BRONZE SELECTION
        </label>
        <label htmlFor="r7" className={s.label}>
          <input type="radio" id="r7" name="tasty" />
          HEETS GOLD SELECTION
        </label>
        <label htmlFor="r8" className={s.label}>
          <input type="radio" id="r8" name="tasty" />
          HEETS GREEN ZING
        </label>
        <label htmlFor="r9" className={s.label}>
          <input type="radio" id="r9" name="tasty" />
          HEETS PURPLE WAVE
        </label>
        <label htmlFor="r10" className={s.label}>
          <input type="radio" id="r10" name="tasty" />
          HEETS RUBY FUSE
        </label>
        <label htmlFor="r11" className={s.label}>
          <input type="radio" id="r11" name="tasty" />
          HEETS SATIN FUSE
        </label>
        <label htmlFor="r12" className={s.label}>
          <input type="radio" id="r12" name="tasty" />
          HEETS SLATE SELECTION
        </label>
        <label htmlFor="r13" className={s.label}>
          <input type="radio" id="r13" name="tasty" />
          HEETS SUMMER BREEZE
        </label>
        <label htmlFor="r14" className={s.label}>
          <input type="radio" id="r14" name="tasty" />
          HEETS TURQUOISE SELECTION
        </label>
        <label htmlFor="r15" className={s.label}>
          <input type="radio" id="r15" name="tasty" />
          HEETS SUN
        </label>
        <label htmlFor="r16" className={s.label}>
          <input type="radio" id="r16" name="tasty" />
          HEETS ARBOR
        </label>
        <label htmlFor="r17" className={s.label}>
          <input type="radio" id="r17" name="tasty" />
          Fiit Regular Deep
        </label>
        <label htmlFor="r18" className={s.label}>
          <input type="radio" id="r18" name="tasty" />
          Fiit Regular
        </label>
        <label htmlFor="r19" className={s.label}>
          <input type="radio" id="r19" name="tasty" />
          Fiit Marine
        </label>
        <label htmlFor="r20" className={s.label}>
          <input type="radio" id="r20" name="tasty" />
          Fiit Tropic
        </label>
        <label htmlFor="r21" className={s.label}>
          <input type="radio" id="r21" name="tasty" />
          Fiit Viola
        </label>
        <label htmlFor="r22" className={s.label}>
          <input type="radio" id="r1" name="tasty" />
          Fiit Spring
        </label>
        <label htmlFor="r23" className={s.label}>
          <input type="radio" id="r23" name="tasty" />
          Никакой
        </label> */}
        <button type="submit" className={styles.button}>
          Ответить
        </button>
      </div>
    </form>
  );
}
