import React from 'react';
import s from './FourthQuestion.module.css';
import styles from '../../styles.module.css';
import { closeApp } from '../../utils/constants';

export default function FourthQuestion({ openFifth, openFinal }) {
  const onSubmit = (e) => {
    e.preventDefault();
    const answers = e.target.querySelectorAll('input[name="capsul"]');
    for (const answer of answers) {
      if (answer.checked) {
        if (answer.value === 'c1') {
          openFifth();
        } else {
          closeApp();
        }
      }
    }
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h1>Пробовали ли вы стики с капсулой?</h1>
      <div className={s.container}>
        <label htmlFor="c1">
          <input type="radio" id="c1" name="capsul" value="c1" required />
          Да
        </label>
        <label htmlFor="c2">
          <input type="radio" id="c2" name="capsul" value="c2" />
          Нет
        </label>
        <label htmlFor="c3">
          <input type="radio" id="c3" name="capsul" value="c3" />
          Не помню
        </label>
        <button type="submit" className={styles.button}>
          Ответить
        </button>
      </div>
    </form>
  );
}
