import React, { useEffect } from 'react';
import s from './SeventhQuestion.module.css';
import styles from '../../styles.module.css';
import { closeApp } from '../../utils/constants';

export default function SeventhQuestion({ openEighth, tasty }) {
  let counter = 0;
  let previos = 0;
  useEffect(() => {
    Object.values(tasty).map((value) => {
      if (value) {
        previos = previos + 1;
      }
    });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();

    const answers = e.target.querySelectorAll('select');

    for (const answer of answers) {
      if (answer.value === '0') {
        counter = counter + 1;
      }
    }

    if (counter === previos) {
      closeApp();
    } else {
      openEighth();
    }
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h3>Как регулярно сейчас вы используете стики с капсулой?</h3>
      <div className={s.container}>
        {tasty.sun && (
          <div className={s.tastyContainer}>
            <h2>SUN</h2>
            <select name="sun" id="sun" className={s.select}>
              <option>Каждый день</option>
              <option>Регулярно, но не каждый день</option>
              <option>Периодически</option>
              <option>Редко</option>
              <option>Не использую</option>
              <option>Купил одну пачку и больше не покупал</option>
              <option value="0">Не пробовал эти стики</option>
            </select>
            {/* <label htmlFor="s1" className={s.label}>
              <input type="radio" id="s1" name="sun" required />
              Каждый день
            </label>
            <label htmlFor="s2" className={s.label}>
              <input type="radio" id="s2" name="sun" />
              Регулярно, но не каждый день
            </label>
            <label htmlFor="s3" className={s.label}>
              <input type="radio" id="s3" name="sun" />
              Периодически
            </label>
            <label htmlFor="s4" className={s.label}>
              <input type="radio" id="s4" name="sun" />
              Редко
            </label>
            <label htmlFor="s5" className={s.label}>
              <input type="radio" id="s5" name="sun" />
              Не использую
            </label>
            <label htmlFor="s6" className={s.label}>
              <input type="radio" id="s6" name="sun" />
              Купил одну пачку и больше не покупал
            </label>
            <label htmlFor="s7" className={s.label}>
              <input type="radio" id="s7" name="sun" />
              Не пробовал эти стики
            </label> */}
          </div>
        )}
        {tasty.arbor && (
          <div className={s.tastyContainer}>
            <h2>ARBOR</h2>
            <select name="arbor" id="arbor" className={s.select}>
              <option>Каждый день</option>
              <option>Регулярно, но не каждый день</option>
              <option>Периодически</option>
              <option>Редко</option>
              <option>Не использую</option>
              <option>Купил одну пачку и больше не покупал</option>
              <option value="0">Не пробовал эти стики</option>
            </select>
            {/* <label htmlFor="a1" className={s.label}>
              <input type="radio" id="a1" name="arbor" />
              Каждый день
            </label>
            <label htmlFor="a2" className={s.label}>
              <input type="radio" id="a2" name="arbor" />
              Регулярно, но не каждый день
            </label>
            <label htmlFor="a3" className={s.label}>
              <input type="radio" id="a3" name="arbor" />
              Периодически
            </label>
            <label htmlFor="a4" className={s.label}>
              <input type="radio" id="a4" name="arbor" />
              Редко
            </label>
            <label htmlFor="a5" className={s.label}>
              <input type="radio" id="a5" name="arbor" />
              Не использую
            </label>
            <label htmlFor="a6" className={s.label}>
              <input type="radio" id="a6" name="arbor" />
              Купил одну пачку и больше не покупал
            </label>
            <label htmlFor="a7" className={s.label}>
              <input type="radio" id="a7" name="arbor" />
              Не пробовал эти стики
            </label> */}
          </div>
        )}
        {tasty.twilight && (
          <div className={s.tastyContainer}>
            <h2>TWILIGHT</h2>
            <select name="twilight" id="twilight" className={s.select}>
              <option className={s.option}>Каждый день</option>
              <option className={s.option}>Регулярно, но не каждый день</option>
              <option className={s.option}>Периодически</option>
              <option className={s.option}>Редко</option>
              <option className={s.option}>Не использую</option>
              <option className={s.option}>
                Купил одну пачку и больше не покупал
              </option>
              <option value="0" className={s.option}>
                Не пробовал эти стики
              </option>
            </select>
            {/* <label htmlFor="t1" className={s.label}>
              <input type="radio" id="t1" name="twilight" />
              Каждый день
            </label>
            <label htmlFor="t2" className={s.label}>
              <input type="radio" id="t2" name="twilight" />
              Регулярно, но не каждый день
            </label>
            <label htmlFor="t3" className={s.label}>
              <input type="radio" id="t3" name="twilight" />
              Периодически
            </label>
            <label htmlFor="t4" className={s.label}>
              <input type="radio" id="t4" name="twilight" />
              Редко
            </label>
            <label htmlFor="t5" className={s.label}>
              <input type="radio" id="t5" name="twilight" />
              Не использую
            </label>
            <label htmlFor="t6" className={s.label}>
              <input type="radio" id="t6" name="twilight" />
              Купил одну пачку и больше не покупал
            </label>
            <label htmlFor="t7" className={s.label}>
              <input type="radio" id="t7" name="twilight" />
              Не пробовал эти стики
            </label> */}
          </div>
        )}
      </div>
      <button type="submit" className={styles.button}>
        Ответить
      </button>
    </form>
  );
}
