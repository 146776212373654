import React from 'react';
import s from './EighthQuestion.module.css';
import styles from '../../styles.module.css';
import { closeApp } from '../../utils/constants';

export default function EighthQuestion({ openFinal }) {
  const onSubmit = (e) => {
    e.preventDefault();
    closeApp();
  };
  return (
    <form className={s.form} onSubmit={onSubmit}>
      <h1>А как бы вы описали вкус HEETS Twilight?</h1>
      <input type="text" required />
      <button type="submit" className={styles.button}>
        Ответить
      </button>
    </form>
  );
}
